/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateUserReflectionEventCommand } from '../model/createUserReflectionEventCommand';
import { CreateUserReflectionEventCommandResult } from '../model/createUserReflectionEventCommandResult';
import { GetAssignedReflectionsQueryResult } from '../model/getAssignedReflectionsQueryResult';
import { GetSingleUserReflectionEventQueryResult } from '../model/getSingleUserReflectionEventQueryResult';
import { GetUserReflectionEventQueryResult } from '../model/getUserReflectionEventQueryResult';
import { GetUserReflectionEventStatisticsQueryResult } from '../model/getUserReflectionEventStatisticsQueryResult';
import { GetUserReflectionEventsQueryResult } from '../model/getUserReflectionEventsQueryResult';
import { GetUserReflectionQuestionsQueryResult } from '../model/getUserReflectionQuestionsQueryResult';
import { GetUserReflectionStatisticsQueryResult } from '../model/getUserReflectionStatisticsQueryResult';
import { GetUserReflectionSubmissionsQueryResult } from '../model/getUserReflectionSubmissionsQueryResult';
import { InlineResponse400 } from '../model/inlineResponse400';
import { SubmitAnswerCommand } from '../model/submitAnswerCommand';
import { UpdateUserQuestionAnswerCommand } from '../model/updateUserQuestionAnswerCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserReflectionEventsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * [MOBILE] Client gets all reflections for selected date
     * 
     * @param selectedDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsGet(selectedDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserReflectionEventsQueryResult>;
    public userReflectionEventsGet(selectedDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserReflectionEventsQueryResult>>;
    public userReflectionEventsGet(selectedDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserReflectionEventsQueryResult>>;
    public userReflectionEventsGet(selectedDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (selectedDateLocal !== undefined && selectedDateLocal !== null) {
            queryParameters = queryParameters.set('SelectedDateLocal', <any>selectedDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserReflectionEventsQueryResult>('get',`${this.basePath}/UserReflectionEvents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets all personal reflection events for selected client
     * 
     * @param clientUid 
     * @param startDateTimeUTC 
     * @param endDateTimeUTC 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsGetAssignedReflectionsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'body', reportProgress?: boolean): Observable<GetAssignedReflectionsQueryResult>;
    public userReflectionEventsGetAssignedReflectionsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetAssignedReflectionsQueryResult>>;
    public userReflectionEventsGetAssignedReflectionsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetAssignedReflectionsQueryResult>>;
    public userReflectionEventsGetAssignedReflectionsGet(clientUid?: string, startDateTimeUTC?: string, endDateTimeUTC?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (startDateTimeUTC !== undefined && startDateTimeUTC !== null) {
            queryParameters = queryParameters.set('StartDateTimeUTC', <any>startDateTimeUTC);
        }
        if (endDateTimeUTC !== undefined && endDateTimeUTC !== null) {
            queryParameters = queryParameters.set('EndDateTimeUTC', <any>endDateTimeUTC);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetAssignedReflectionsQueryResult>('get',`${this.basePath}/UserReflectionEvents/GetAssignedReflections`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets user statistic for assigned event
     * 
     * @param uid 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsGetEventStatisticsGet(uid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserReflectionEventStatisticsQueryResult>;
    public userReflectionEventsGetEventStatisticsGet(uid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserReflectionEventStatisticsQueryResult>>;
    public userReflectionEventsGetEventStatisticsGet(uid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserReflectionEventStatisticsQueryResult>>;
    public userReflectionEventsGetEventStatisticsGet(uid?: string, startDateLocal?: string, endDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uid !== undefined && uid !== null) {
            queryParameters = queryParameters.set('Uid', <any>uid);
        }
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserReflectionEventStatisticsQueryResult>('get',`${this.basePath}/UserReflectionEvents/GetEventStatistics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets reflcetion questions and client submission per day
     * 
     * @param clientUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsGetUserRelfectionQuestionsGet(clientUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserReflectionQuestionsQueryResult>;
    public userReflectionEventsGetUserRelfectionQuestionsGet(clientUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserReflectionQuestionsQueryResult>>;
    public userReflectionEventsGetUserRelfectionQuestionsGet(clientUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserReflectionQuestionsQueryResult>>;
    public userReflectionEventsGetUserRelfectionQuestionsGet(clientUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserReflectionQuestionsQueryResult>('get',`${this.basePath}/UserReflectionEvents/GetUserRelfectionQuestions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Create reflection event for selected client
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsPost(body?: CreateUserReflectionEventCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateUserReflectionEventCommandResult>;
    public userReflectionEventsPost(body?: CreateUserReflectionEventCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateUserReflectionEventCommandResult>>;
    public userReflectionEventsPost(body?: CreateUserReflectionEventCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateUserReflectionEventCommandResult>>;
    public userReflectionEventsPost(body?: CreateUserReflectionEventCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateUserReflectionEventCommandResult>('post',`${this.basePath}/UserReflectionEvents`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client gets single reflection and answers for selected date
     * 
     * @param reflectionEventUid 
     * @param submittedDateLocal 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsReflectionEventUidGet(reflectionEventUid: string, submittedDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserReflectionEventQueryResult>;
    public userReflectionEventsReflectionEventUidGet(reflectionEventUid: string, submittedDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserReflectionEventQueryResult>>;
    public userReflectionEventsReflectionEventUidGet(reflectionEventUid: string, submittedDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserReflectionEventQueryResult>>;
    public userReflectionEventsReflectionEventUidGet(reflectionEventUid: string, submittedDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (reflectionEventUid === null || reflectionEventUid === undefined) {
            throw new Error('Required parameter reflectionEventUid was null or undefined when calling userReflectionEventsReflectionEventUidGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (submittedDateLocal !== undefined && submittedDateLocal !== null) {
            queryParameters = queryParameters.set('SubmittedDateLocal', <any>submittedDateLocal);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserReflectionEventQueryResult>('get',`${this.basePath}/UserReflectionEvents/${encodeURIComponent(String(reflectionEventUid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets user statistic for assigned reflections
     * 
     * @param clientUid 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsStatisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserReflectionStatisticsQueryResult>;
    public userReflectionEventsStatisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserReflectionStatisticsQueryResult>>;
    public userReflectionEventsStatisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserReflectionStatisticsQueryResult>>;
    public userReflectionEventsStatisticsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserReflectionStatisticsQueryResult>('get',`${this.basePath}/UserReflectionEvents/Statistics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client gets all submitted reflections for selected date range
     * 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserReflectionSubmissionsQueryResult>;
    public userReflectionEventsSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserReflectionSubmissionsQueryResult>>;
    public userReflectionEventsSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserReflectionSubmissionsQueryResult>>;
    public userReflectionEventsSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserReflectionSubmissionsQueryResult>('get',`${this.basePath}/UserReflectionEvents/Submissions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client submits question answers
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsSubmitAnswersPost(body?: SubmitAnswerCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userReflectionEventsSubmitAnswersPost(body?: SubmitAnswerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userReflectionEventsSubmitAnswersPost(body?: SubmitAnswerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userReflectionEventsSubmitAnswersPost(body?: SubmitAnswerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/UserReflectionEvents/SubmitAnswers`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client Updates Text on reflection input 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsUidPut(uid: string, body?: UpdateUserQuestionAnswerCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userReflectionEventsUidPut(uid: string, body?: UpdateUserQuestionAnswerCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userReflectionEventsUidPut(uid: string, body?: UpdateUserQuestionAnswerCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userReflectionEventsUidPut(uid: string, body?: UpdateUserQuestionAnswerCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userReflectionEventsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserReflectionEvents/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Preview of single user relfection event in draft mode
     * 
     * @param uid 
     * @param clientUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userReflectionEventsUserReflectionEventUidGet(uid: string, clientUid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetSingleUserReflectionEventQueryResult>;
    public userReflectionEventsUserReflectionEventUidGet(uid: string, clientUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSingleUserReflectionEventQueryResult>>;
    public userReflectionEventsUserReflectionEventUidGet(uid: string, clientUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSingleUserReflectionEventQueryResult>>;
    public userReflectionEventsUserReflectionEventUidGet(uid: string, clientUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userReflectionEventsUserReflectionEventUidGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSingleUserReflectionEventQueryResult>('get',`${this.basePath}/UserReflectionEvents/UserReflectionEvent/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
